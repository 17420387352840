import React, { Fragment } from 'react';
import "./work-slider.scss";
import { Heading } from "../../components/Heading/Heading";
// core version + navigation, pagination modules:
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CardWorkSlider } from "../../components/CardWorkSlider/CardWorkSlider";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { graphql, useStaticQuery } from "gatsby";
import { CircleDecoration } from "../CircleDecoration/CircleDecoration";
import { Button } from "../Button/Button";
import classNames from "classnames";
import { ArrowRight } from "react-feather";

const IMAGE_QUERY = graphql`
  {
    bahlerImage: file(
      relativePath: { eq: "homepage/slider/work-bahler.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    wintradoImage: file(
      relativePath: { eq: "homepage/slider/work-wintrado.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    jakobMullerImage: file(
      relativePath: { eq: "homepage/slider/work-mymuenet.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dutyDingoImage: file(relativePath: { eq: "homepage/slider/work-dutydingo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

export const WorkSlider = ({
  title,
  description,
  variant,
  hideControls,
  hideCtaButton,
}) => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <>
      <section className={classNames(`work-slider`, `work-slider--${variant}`)}>
        <div className="work-slider__text">
          <CircleDecoration
            variant="work_slider"
            position="top_left"
          ></CircleDecoration>
          <div className="gr-12 no-gutter@md">
            <div className="work-slider__holder">
              <div className="work-slider__wrap">
                <div className="work-slider__intro">
                  <Heading level="h2" decorationDown>
                    Our work
                  </Heading>
                  <p className="work-slider__subtitle">
                    Discover how we've helped leading companies across
                    industries achieve success with our innovative solutions.
                    Browse our case studies for real-world examples.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--fullwidth work-slider__slider-holder">
          <div className="gr-12 no-gutter">
            <div className="work-slider__slider">
              <Swiper
                className="work-swiper"
                modules={[Navigation, Pagination, EffectCoverflow]}
                slidesPerView="1.3"
                effect={"coverflow"}
                loop={true}
                grabCursor={true}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 0,
                  modifier: 0,
                  slideShadows: false,
                }}
                navigation={{
                  el: ".work-slider-navigation",
                  nextEl: ".next",
                  prevEl: ".prev",
                }}
                pagination={{
                  type: "fraction",
                  el: ".work-slider-pagination",
                  formatFractionCurrent: function (number) {
                    if (number < 9) {
                      return "0" + number;
                    }
                  },
                  formatFractionTotal: function (current) {
                    if (current < 9) {
                      return "0" + current;
                    }
                  },
                }}
              >
                <SwiperSlide>
                  <CardWorkSlider
                    link="/our-work/kickstage-develops-mymunet-an-advanced-iot-platform-for-jakob-muller-group"
                    projectTitle="Jakob Müller"
                    projectDescription="Scalable IOT platform connecting textile machines around the globe. Concept, Design, Development & Operations."
                    projectCtaText="See the impact"
                    image={tempImage.jakobMullerImage}
                    variation="jakob_muller"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardWorkSlider
                    link="/our-work/kickstage-develops-ai-powered-chatbot-for-dutydingo/"
                    projectTitle="DutyDingo"
                    projectDescription="This innovative tool automatically turns Slack conversations into tasks with seamless integration into Trello, Jira, Asana, and ClickUp. Concept, Design, Development & Operations."
                    projectCtaText="Automate chat into Tasks – Learn More"
                    image={tempImage.dutyDingoImage}
                    variation="jakob_muller"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardWorkSlider
                    link="/our-work/#wintrado"
                    projectTitle="Wintrado Technologies"
                    projectDescription="Powerful multi-asset trading application, CRM & customer backoffice and real-time engine. Consulting, Design, Development."
                    projectCtaText="Why latency matters"
                    image={tempImage.wintradoImage}
                    variation="wintrado"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <CardWorkSlider
                    link="/our-work/#bahler"
                    projectTitle="Bähler Scansoles"
                    projectDescription="AI-powered mobile app that creates personalized insoles by analyzing customer-submitted pictures. UX Consulting, Design, Development."
                    projectCtaText="How AI enables it"
                    image={tempImage.bahlerImage}
                    variation="bahler"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="container work-slider__main-controls">
          {!hideControls && (
            <div className="work-slider__controls-holder">
              <div className="work-slider__controls work-slider-navigation">
                <div className="swiper-button-prev prev"></div>
                <div className="swiper-button-next next"></div>
              </div>
              <div className="work-slider__pagination work-slider-pagination">
                <span className="swiper-pagination-current"></span>
                <span className="swiper-pagination-total"></span>
              </div>
            </div>
          )}
          {!hideCtaButton && (
            <div className="work-slider__cta">
              <Button
                link="/our-work/"
                secondary
                withIcon
                icon={
                  <Fragment>
                    <ArrowRight size="24" />
                  </Fragment>
                }
              >
                See all projects
              </Button>
            </div>
          )}
        </div>
        {!hideCtaButton && (
          <div className="container work-slider__mobile-cta">
            <Button
              link="/our-work/"
              secondary
              withIcon
              icon={
                <Fragment>
                  <ArrowRight size="24" />
                </Fragment>
              }
            >
              See all projects
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default WorkSlider;
